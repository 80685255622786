<template>
  <div>
    <h1>Start a Trial</h1>

    <p>Want to see how easy our platform works? Start a personalized trial event with 3 clicks and invite up to 5 co-workers to join your 14-day trial event. No credit card needed.</p>

    <p>Your challenge will be instantly available after submitting a few details. Feel free to <router-link :to="{name: 'contact'}">reach out to us</router-link> in case you have questions during the setup or execution of your trial.</p>
    
    <p>Please note: trial events are optimized to try out our platform. Not all features are available in trial events for the sake of simplicity. Want to create an event to go live with or try out all settings? Create a <router-link :to="{name: 'eventmanagerCreate'}">real event instead</router-link>. </p>
  
    <v-form lazy-validation ref="form" @submit="submit">

      <v-row class="mb-0 mx-0 align-center">
        <v-col cols="12" class="mb-0 pb-0">
          <h3 class="mb-0 pb-0">Step 1 of 3: What Type of Challenge would you want to start?</h3>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-radio-group v-model="request.create_teams" row class="mt-4 justify-center">
            <v-radio label="Individual Only" :value="false"></v-radio>
            <v-radio label="Individual and Teams" :value="true"></v-radio>
          </v-radio-group>
          <!-- <v-checkbox v-model="request.create_teams" label="Also create a team leaderboard" /> -->
          <v-alert v-if="request.create_teams" type="info" outlined class="align-left">
            <p>We will add a team leaderboard to your event and create two teams ('Team A' and 'Team B'). Participants are randomly assigned to one of these teams as they join.</p>
            <p>With real events you have many more options available to customize team leaderboards. E.g. self-managed teams, pre-assigned teams, etc. You can then also do average or total based team leaderboards.</p>
          </v-alert>
        </v-col>
        <v-col cols="12" sm="4">
          <BigButton 
            elevated 
            :color="request.scoring=='STEPS'?'primary':''" 
            icon="fal fa-shoe-prints"
            icon-color="black"
            title="Step Challenge" 
            text="Total steps tracked throughout the day." 
            @click="request.scoring='STEPS'"
            />
        </v-col>
        <v-col cols="12" sm="4">
          <BigButton 
            elevated 
            :color="request.scoring=='TIME'?'primary':''" 
            icon="fal fa-hourglass-half"
            icon-color="black"
            title="Total Active Time" 
            text="Accumulated time from your activities." 
            @click="request.scoring='TIME'"
            />
        </v-col>
        <v-col cols="12" sm="4">
          <BigButton 
            elevated 
            :color="request.scoring=='DISTANCE'?'primary':''" 
            icon="fal fa-ruler"
            icon-color="black"
            title="Total Distance" 
            text="Accumulated (GPS) distance from your activities." 
            @click="request.scoring='DISTANCE'"/>
        </v-col>
      </v-row>
      <v-row v-if="request.scoring == 'DISTANCE'" class="">
        <v-col cols="12" >
          <p>Choose your measuring system ?</p>
          <v-radio-group v-model="request.unit" row class="mt-0" >
            <v-radio label="Metric (kilometers)" value="METRIC"></v-radio>
            <v-radio label="Imperial (miles)" value="IMPERIAL"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row  v-if="request.scoring" class="mb-8 mx-0 align-center">
        <v-col cols="12" class="mb-0 pb-0">
          <h3 class="mb-0 pb-0">Step 2 of 3: Select your Template</h3>
          <p>Select one of the two templates to get started with a ready-to-go journey. We configure the template in a way that allows you to complete the challenge in 14 days.</p>
        </v-col>
        <v-col cols="12" sm="4">
          <BigButton 
            elevated 
            :color="request.template=='us-national-parks'?'primary':''" 
            icon="fal fa-mountains"
            icon-color="black"
            title="US National Parks" 
            text="Visit some of the greatest national parks in the USA. Start at the Grand Canyon and finish in the Rocky Mountains." 
            @click="request.template='us-national-parks'"
            />
        </v-col>
        <v-col cols="12" sm="4">
          <BigButton 
            elevated 
            :color="request.template=='european-landmarks'?'primary':''" 
            icon="fal fa-landmark"
            icon-color="black"
            title="European Landmarks" 
            text="Visit the most famous places across Europe. Start at the Eiffel Tower and finish in Stonehenge." 
            @click="request.template='european-landmarks'"
            />
        </v-col>
        <v-col cols="12" sm="4">
          <BigButton 
            elevated 
            :color="request.template==''?'primary':''" 
            icon="fal fa-pencil-ruler"
            icon-color="black"
            title="Blank" 
            text="Just the leaderboard without maps or milestones." 
            @click="request.template=''"/>
        </v-col>
      </v-row>


      <v-row  v-if="request.template != null" class="mb-8 mx-0 align-center">
        <v-col cols="12" class="mb-0 pb-0">
          <h3 class="mb-0 pb-0">Step 3 of 3: Final Details</h3>
          <p>Enter your details so we can generate your trial challenge.</p>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="request.email"
            :rules="emailRules"
            label="Your Email"
            required
            hint="We will send instructions to help you get started when your challenge is ready."
            />

          <v-text-field
            v-model="request.org"
            :rules="orgRules"
            label="Organization Name"
            required
            />

          <v-btn x-large color="primary" class="mt-8" @click="submit" :loading="$store.getters.isLoading">Create Trial Challenge</v-btn>
          <!-- <v-alert type="info">
            Trial events are temporarily unavailable. Please check in a few days to start your trial event.
          </v-alert> -->
        </v-col>
      </v-row>

    </v-form>

    <LoginOrRegisterDialog ref="loginForm" mode="REGISTER" message="In order to create your trial event you'd need to either create a new account or login with your current account." />

  </div>
</template>


<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import { DateTime } from 'luxon'
import BigButton from "@/components/BigButton";
import LoginOrRegisterDialog from '@/components/LoginOrRegisterDialog.vue';
import profileService from "@/services/profileService";
import eventManagerService from "@/services/eventManagerService";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "StartTrial",
  components: {
    BigButton,
    LoginOrRegisterDialog,
  },
  props: {
  },
  data: function() {
    return {
      valid: true,
      timeZoneOlson: null,
      showLoginDialog: false,
      request: {
        org: '',
        email: '',
        scoring: '',
        create_teams: false,
        template: null,
        time_zone: null,
        unit: 'IMPERIAL',
      },
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      orgRules: [
        v => !!v || 'Please enter the name of your organization',
      ],
    };
  },
  created() {
  },
  mounted() {
    this.request.time_zone = DateTime.now().zoneName;

    // test data
    /*
    if (process.env.NODE_ENV !== 'production') {
      this.request.org ="test";
      this.request.email = "neokoen@gmail.com";
      this.request.scoring = 'STEPS';
      this.request.template = '';
    }
    */

    EventBus.$on('login-state-change', async user => {
      if (this.showLoginDialog && user) {
        // logged in during the process, now start trial
        this.submit();
      }
    });
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        if (this.user) {
          console.log('Preparing with request', this.request);
          try {
          const response = await eventManagerService.createTrial(this.request);
          console.log('Result', response.data.id, response.data);
          if (response.data) {
            this.$analytics.event('trial_create', { 'event_category': 'trial', 'event_label': 'created' });
            this.$router.replace({ name: 'trial', params: {id: response.data.id}});
          }
          else {
            this.$helpers.toastResponse(this, response.data);
          }
          }
          catch (ex) {
            console.log('Error Result', ex);
          }
        }
        else {
          this.showLoginDialog = true;
          this.$refs.loginForm.open(this.request.email);
        }
      }
    },

    
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
  watch: {
  }
};
</script>

<style scoped>
.justify-center::v-deep .v-input--radio-group__input { justify-content: center !important; }
</style>